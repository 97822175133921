import AAChartersLogo from '../../../img/AAChartersLogo.png';
import '../ChartersLanding/Header.css';

//Charters Logo
var chartersLogoImg = '<img src='+AAChartersLogo+' width="300px" class="img-fluid" alt="American Airlines Charters" title="American Airlines Charters - Home"/>';
var url;
if(window.location.href.search('stage')>-1){
    url = "https://charters.stage.aa.com/charters";
}else if(window.location.href.search('dev') >-1 || window.location.href.search('localhost')>-1){
    url = "https://charters.dev.aa.com/charters";
}else{
    url = "https://charters.aa.com/charters";
}

var headerForChartersLanding = '<div id="chartersHeaderDiv" class="shadow"><nav class="navbar navbar-toggleable-md navbar-inverse nav1"><a id="charterslogo" class="navbar-brand float-lg-left float-xs-right float-sm-right float-md-left logoImg" href="">' + chartersLogoImg + '</a><button id = "hamburgerMenu" class=" float-xs-left float-sm-left hidden-md-up hamburgermenu" type="button" data-toggle="collapse" data-target="#navigation" ><i class="icon-menu" style="font-size:30px;"></i></button><div class="collapse navbar-toggleable-sm"id="navigation"><ul class="nav navbar-nav nav_links"><li class="nav-item"><button class="button-navlink" data-toggle="collapse" href="#charterexperienceexpanded" id="charter-experience" >CHARTER EXPERIENCE</button></li><li class="nav-item"><a href="' + url +'"><button class="btn-secondary" id="login" >LOG IN</button></a></li><li class="nav-item"><a target="_blank" href="https://c03.keysurvey.com/f/116913/d778/"><button class="btn-primary">REQUEST A CHARTER</button></a></li></ul></div></nav></div><div id="charterexperienceexpanded" class="navbar-collapse collapse charterexperienceexpanded"><ul class="nav navbar-nav submenuul nav_links"><li class="nav-item"><a class="submenuatags" id="pytSubmenu" href="#">Planning your trip</a></li><li class="nav-item"><a class="submenuatags" id="ttjSubmenu" href="#">Throughout the journey</a></li><li class="nav-item"><a class="submenuatags" id="otgSubmenu" href="#">On the ground</a></li><li class="nav-item"><a id="obaitaSubmenu" class="submenuatags" href="#">Onboard and in the air</a></li></ul><div class="text-xs-center"><a id="caretupsubmenu" class="caretuparrow shadow" data-toggle="collapse" data-target="#charterexperienceexpanded" href="#"><i class="fa fa-caret-up fa-2x"></i></a></div></div>';


document.getElementById("mainContainer").innerHTML = headerForChartersLanding;
